import React from "react"
import Layout from '../../components/layout';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { graphql } from 'gatsby';
import Img from "gatsby-image"
const decorativePaintsPage = ({ data }) => (
	<Layout>
		<Row>
			<Col>
				<h1>Decoration Paints</h1>
			</Col>
		</Row>
		{/* Sonam Royal Interior Emulsion */}
		<Row>
			<Col>
				<h2>Sonam Royal Interior Emulsion</h2>

			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={8}>
				<p>Interior emulsion having a slight sheen finish, durable, washable, water–based modified acrylic emulsion paint for interior walls. It provides a smooth finish over the interior primer. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces.</p>
				<Table >
					<tbody>
						<tr>
							<td>Packaging Available:</td>
							<td>20 ltr, 10 ltr,4ltr,1ltr</td>
						</tr>
						<tr>
							<td>Color:</td>
							<td>White</td>
						</tr>
						<tr>
							<td>Finish:</td>
							<td>Smooth, Matt</td>
						</tr>
						<tr>
							<td>Drying time:</td>
							<td>surface dry time 20 min</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col xs={12} sm={4} md={{span:3,offset:1}}>
				<Img fluid={data.royalInterior.childImageSharp.fluid}></Img></Col>
		</Row>
		{/* Sonam Exterior Emulsion */}
		<Row>
			<Col>
				<h2>Sonam Exterior Emulsion</h2>

			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={8}>
				<p>Exterior emulsion is a specially formulated high performance exterior emulsion paint. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces. Properties like water-resistance, dirt-pickup-resistance, anti-algae, anti-fungi, alkali-resistance and uv-resistance. These exterior emulsions will protect and preserve the freshness of your home and the impression it leaves.

</p>
				<Table >
					<tbody>
						<tr>
							<td>Packaging Available:</td>
							<td>20 ltr, 10 ltr,4ltr,1ltr</td>
						</tr>
						<tr>
							<td>Color:</td>
							<td>White</td>
						</tr>
						<tr>
							<td>Finish:</td>
							<td>Smooth, Matt</td>
						</tr>
						<tr>
							<td>Drying time:</td>
							<td>surface dry time 20 min</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col xs={12} sm={4} md={{span:3,offset:1}}>
				<Img fluid={data.exterior.childImageSharp.fluid}></Img></Col>
		</Row>

		{/* Sonam Sealing Coat */}
		<Row>
			<Col>
				<h2>Sonam Sealing Coat</h2>

			</Col >
		</Row>
		<Row>
			<Col xs={12} sm={8}>
				<p>Interior Sealing coat is a specially formulated high performance interior emulsion paint. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surface. A low voc acrylic sealer undercoat with excellent hiding power and filling properties for use on interior plasterboard and textured surfaces.</p>
				<Table >
					<tbody>
						<tr>
							<td>Packaging Available:</td>
							<td>20 ltr, 10 ltr,4ltr,1ltr</td>
						</tr>
						<tr>
							<td>Color:</td>
							<td>White</td>
						</tr>
						<tr>
							<td>Finish:</td>
							<td>Smooth, Matt</td>
						</tr>
						<tr>
							<td>Drying time:</td>
							<td>surface dry time 20 min</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col xs={12} sm={4} md={{span:3,offset:1}}>
				<Img fluid={data.sealingCoat.childImageSharp.fluid}></Img></Col>
		</Row>
		{/* Sonam Interior Emulsion */}
		<Row>
			<Col>
				<h2>Sonam Interior Emulsion</h2>

			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={8}>
				<p>Interior emulsion having a slight sheen finish, durable, washable, water–based modified acrylic emulsion paint for interior walls. It provides a smooth finish over the interior primer. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces.</p>
				<Table >
					<tbody>
						<tr>
							<td>Packaging Available:</td>
							<td>20 ltr, 10 ltr,4ltr,1ltr</td>
						</tr>
						<tr>
							<td>Color:</td>
							<td>White</td>
						</tr>
						<tr>
							<td>Finish:</td>
							<td>Smooth, Matt</td>
						</tr>
						<tr>
							<td>Drying time:</td>
							<td>surface dry time 20 min</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col xs={12} sm={4} md={{span:3,offset:1}}>
				<Img fluid={data.interiorEmulsion.childImageSharp.fluid}></Img></Col>
		</Row>
		{/* Sonam Weatherproof Exterior Emulsion */}
		<Row>
			<Col>
				<h2>Sonam Weatherproof Exterior Emulsion</h2>

			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={8}>
				<p>Exterior emulsion is a specially formulated high performance exterior emulsion paint. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces.</p>
				<Table >
					<tbody>
						<tr>
							<td>Packaging Available:</td>
							<td>20 ltr, 10 ltr,4ltr,1ltr</td>
						</tr>
						<tr>
							<td>Color:</td>
							<td>White</td>
						</tr>
						<tr>
							<td>Finish:</td>
							<td>Smooth, Matt</td>
						</tr>
						<tr>
							<td>Drying time:</td>
							<td>surface dry time 20 min</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col xs={12} sm={4} md={{span:3,offset:1}}>
				<Img fluid={data.weatherProofExterior.childImageSharp.fluid}></Img></Col>
		</Row>
		{/* Sonam Royal Interior Emulsion */}
		<Row>
			<Col>
				<h2>Sonam Royal Interior Emulsion</h2>

			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={8}>
				<p>Interior emulsion having a slight sheen finish, durable, washable, water–based modified acrylic emulsion paint for interior walls. It provides a smooth finish over the interior primer. It can be used on all types of interior cement plasters, interior ceilings, asbestos sheets, concrete and existing painted surfaces.</p>
				<Table >
					<tbody>
						<tr>
							<td>Packaging Available:</td>
							<td>20 ltr, 10 ltr,4ltr,1ltr</td>
						</tr>
						<tr>
							<td>Color:</td>
							<td>White</td>
						</tr>
						<tr>
							<td>Finish:</td>
							<td>Smooth, Matt</td>
						</tr>
						<tr>
							<td>Drying time:</td>
							<td>surface dry time 20 min</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col xs={12} sm={4} md={{span:3,offset:1}}>
				<Img fluid={data.royalInteriorBlue.childImageSharp.fluid}></Img></Col>
		</Row>
		{/* Sonam Pearl Glow Premium Metallic Emulsion */}
		<Row>
			<Col>
				<h2>Sonam Pearl Glow Premium Metallic Emulsion</h2>

			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={8}>
				<p>Acrylic wall putty is popularly called as snow white premium wall putty. The raw materials, which we use, ensure the high durability of our products. The white acrylic wall putty is water based and considered ideal for preparing undercoats for walls. The non toxic nature of our wall putty makes it an eco friendly product.

</p>
				<Table >
					<tbody>
						<tr>
							<td>Packaging Available:</td>
							<td>20 ltr, 10 ltr,4ltr,1ltr</td>
						</tr>
						<tr>
							<td>Color:</td>
							<td>White</td>
						</tr>
						<tr>
							<td>Finish:</td>
							<td>Smooth, Matt</td>
						</tr>
						<tr>
							<td>Drying time:</td>
							<td>surface dry time 20 min</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col xs={12} sm={4} md={{span:3,offset:1}}>
				<Img fluid={data.pearlGlow.childImageSharp.fluid}></Img></Col>
		</Row>
		{/* Sonam Pearl Glow Premium Metallic Emulsion */}
		<Row>
			<Col>
				<h2>Sonam Pearl Glow Premium Metallic Emulsion</h2>

			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={8}>
				<p>Shiny metallic emulsion for a stunning shimmer effect. Ideal for creating feature walls, stripes, stencils.</p>
				<Table >
					<tbody>
						<tr>
							<td>Packaging Available:</td>
							<td>20 ltr, 10 ltr,4ltr,1ltr</td>
						</tr>
						<tr>
							<td>Color:</td>
							<td>White</td>
						</tr>
						<tr>
							<td>Finish:</td>
							<td>Smooth, Matt</td>
						</tr>
						<tr>
							<td>Drying time:</td>
							<td>surface dry time 20 min</td>
						</tr>
					</tbody>
				</Table>
			</Col>
			<Col xs={12} sm={4} md={{span:3,offset:1}}>
				<Img fluid={data.glowpremium.childImageSharp.fluid}></Img></Col>
		</Row>
	</Layout>
)

export default decorativePaintsPage;

export const query = graphql`
query {
    royalInterior: file(relativePath: { eq: "royalInterior.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    exterior:file(relativePath:{eq:"exterior.jpg"}){
       childImageSharp {
        fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid
        }
      }
	}
	
	glowpremium: file(relativePath: { eq: "glowpremium.jpg" }) {
		childImageSharp {
		  fluid(maxWidth: 5000) {
			...GatsbyImageSharpFluid
		  }
		}
	  }

	  interiorEmulsion: file(relativePath: { eq: "interiorEmulsion.jpg" }) {
		childImageSharp {
		  fluid(maxWidth: 5000) {
			...GatsbyImageSharpFluid
		  }
		}
	  }

	  interiorEmulsion: file(relativePath: { eq: "interiorEmulsion.jpg" }) {
		childImageSharp {
		  fluid(maxWidth: 5000) {
			...GatsbyImageSharpFluid
		  }
		}
	  }

	  royalInteriorBlue: file(relativePath: { eq: "royalInteriorBlue.jpg" }) {
		childImageSharp {
		  fluid(maxWidth: 5000) {
			...GatsbyImageSharpFluid
		  }
		}
	  }

	  sealingCoat: file(relativePath: { eq: "sealingCoat.jpg" }) {
		childImageSharp {
		  fluid(maxWidth: 5000) {
			...GatsbyImageSharpFluid
		  }
		}
	  }

	  weatherProofExterior: file(relativePath: { eq: "weatherProofExterior.jpg" }) {
		childImageSharp {
		  fluid(maxWidth: 5000) {
			...GatsbyImageSharpFluid
		  }
		}
	  }

	  pearlGlow: file(relativePath: { eq: "pearlGlow.jpg" }) {
		childImageSharp {
		  fluid(maxWidth: 5000) {
			...GatsbyImageSharpFluid
		  }
		}
	  }
  }
`
